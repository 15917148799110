<template>

    <div class="w-full h-full px-3 py-4">
        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6">
            <div class="h-16 flex flex-row px-2">
                <div class="h-full w-10/12 flex flex-row">
                    <div class="h-full w-full flex flex-col justify-center items-start">
                        <span class="text-2xl text-darkgreen">Tops</span>
                        <span class="text-xs text-gray">{{$t('appliedFilters')}}</span>
                    </div>
                </div>
                <div class="h-full w-2/12 flex flex-col justify-start items-end">
                    <i class="mdi mdi-dots-vertical text-gray text-xl" @click="toFilters()"></i>
                </div>
            </div>

            <div class="h-6 flex flex-row justify-start items-center px-2">
                <div class="h-full w-auto px-1 border border-green bg-green_transparent rounded mr-2 flex flex-row justify-center items-center">
                    <span class="text-2xs text-green">{{$t('billing')}}</span>
                </div>
                <div class="h-full w-auto px-1 border border-purple bg-red_transparent rounded mr-2 flex flex-row justify-center items-center">
                    <span class="text-2xs text-purple">{{$t('allCountries')}}</span>
                </div>
                <div class="h-full w-auto px-1 border border-orange bg-orange_transparent rounded mr-2 flex flex-row justify-center items-center">
                    <span class="text-2xs text-orange">{{$t('annual')}}</span>
                </div>
                <div class="h-full w-auto px-1 border border-darkgray bg-darkgray_transparent rounded mr-2 flex flex-row justify-center items-center">
                    <span class="text-2xs" :class="'text-font_gray-'+mode">{{$t('families')}}</span>
                </div>
            </div>

            <!-- <div class="h-8 px-2 mt-6">
                <div class="h-full bg-lightgray flex flex-row">
                    <div class="h-full w-5/12"></div>
                    <div class="h-full w-2/12 flex flex-row justify-center items-center">
                        <span class="text-2xs text-darkgray">{{$t('weight')}}</span>
                    </div>
                    <div class="h-full w-3/12 flex flex-row justify-center items-center">
                        <span class="text-2xs text-darkgray">Evol.</span>
                    </div>
                    <div class="h-full w-2/12 flex flex-row justify-center items-center">
                        <span class="text-2xs text-darkgray">Med</span>
                    </div>
                </div>
            </div> -->

            <div v-for="(el,index) in 9" :key="index" class="h-10 px-2">
                <div class="h-full bg-lightgray flex flex-row">
                    <div class="h-full w-5/12 flex flex-row justify-start items-center px-2">
                        <span class="text-xs font-bold" :class="'text-font_gray-'+mode">A-PVTO</span>
                    </div>
                    <div class="h-full w-2/12 flex flex-row justify-center items-center">
                        <span class="text-xs font-bold" :class="'text-font_gray-'+mode">16%</span>
                    </div>
                    <div class="h-full w-3/12 flex flex-row justify-center items-center">
                        <basiclinechart></basiclinechart>
                    </div>
                    <div class="h-full w-2/12 flex flex-row justify-center items-center">
                        <span class="text-xs font-bold" :class="'text-font_gray-'+mode">47%</span>
                    </div>
                </div>
            </div>
            <div class="h-6"></div>
        </div>
    </div>

</template>

<script>
import basiclinechart from '../../components/basiclinechart.vue';

export default {
    components: {
        basiclinechart
    },
    methods:{
        toFilters() {
            this.$router.push('filters')
        }
    }
}
</script>
